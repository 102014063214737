import { render, staticRenderFns } from "./NotAllow.vue?vue&type=template&id=7f732944&scoped=true&"
import script from "./NotAllow.vue?vue&type=script&lang=js&"
export * from "./NotAllow.vue?vue&type=script&lang=js&"
import style0 from "./NotAllow.vue?vue&type=style&index=0&id=7f732944&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f732944",
  null
  
)

export default component.exports